/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

div[data-column-id="9"] {
	padding: 0 !important;
}
.rdt_TableHeadRow{
	height: 4rem !important;
}

.rdt_TableCol > div > div {
	white-space: pre-wrap !important;
}

.bizFit-button {
  background-color: #4fbd97 !important;
}

.spending-limit-update__input {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

body {
  padding: 0 !important;
}

.bulk-update__input {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}

.form-select.is-invalid {
  background-position: right 1rem center, center right 2.5rem !important;
}

.nav-item a {
  display: flex !important;
  align-items: self-start !important;
}

#html5-qrcode-button-camera-permission, #html5-qrcode-button-file-selection, 
#html5-qrcode-button-camera-stop, #html5-qrcode-button-camera-start {
  color: white;
  background: linear-gradient(118deg, #4fbd97, rgba(79, 189, 151, 0.7));
  box-shadow: 0 0 4px 1px rgba(79, 189, 151, 0.7) !important;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin: 1rem;
}

#html5qr-code-full-region {
  border: none !important;
}

#html5qr-code-full-region div img {
  display: none;
}

#html5qr-code-full-region__dashboard_section div div {
  border-color: rgba(79, 189, 151, 0.7) !important;
  border-width: 4px !important;
}

#html5qr-code-full-region__scan_region img {
  display: none !important;
}

#html5qr-code-full-region__scan_region {
 min-height: 0 !important;
}

#html5qr-code-full-region__header_message{
  border-top: none !important;
  width: 75% !important;
  margin: auto !important;
}

.swal-text {
  white-space: pre-wrap;
}

.navigation-header {
  margin: calc(2rem + 0.286rem) 0 0.8rem 1rem !important;
}

.danger-button {
  background-color: #ea5455 !important;
}

.uni-title {
  display: flex !important;
  margin-right: auto !important;
  align-self: center !important;
  margin-bottom: 0;
}

.reset-filters-btn {
  outline: none !important;
  text-decoration: underline !important;
  background: transparent !important;
  border: none !important;
  display: flex !important; 
  align-items: center !important;
}


.reset-filters-btn:hover {
  outline: none !important;
  text-decoration: underline !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}